<template>
  <div class="editor">
    <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
            <i class="fe fe-bold" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
            <i class="fe fe-italic" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
            <i class="fe fe-code" />
        </button>
        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <i class="fe fe-underline" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <i class="fe fe-list" />
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <a-icon type="ordered-list" />
        </button>

      </div>
    </editor-menu-bubble>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>

import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
export default {
    props:{
        text:{
            default:'',
            type:String
        }
    },
    components: {
        EditorContent,
        EditorMenuBubble,
    },
  data() {
    return {
      keepInBounds: true,
      editor: null,
      updatedHTML:''
    }
  },
  created(){
    console.log('test', this.text)
    this.editor = new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        onUpdate: ({getHTML}) => {
            this.updatedHTML = getHTML()
        },
        onBlur:() =>{

            if (this.updatedHTML == null) {
                this.updatedHTML = ''
            } 
            console.log('HTML', this.updatedHTML)
            this.$emit('save', this.updatedHTML)
        },
        content:this.text,
      })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style>
.mention {
  color: var(--orange);
  background-color: var(--off-white-dark);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}
</style>

<style lang="scss">
.editor__content,.ProseMirror{
    &:focus{
        outline:none;
        background-color:white !important;
        border-bottom:1px black solid
    }
}
.menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color:white;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
    
}
.menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 200000;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s;
    &.is-active{
        opacity:1;
        visibility: visible;
    }
}
</style>